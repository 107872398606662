import React, { Fragment, useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const UnsubscribePage = () => {
  const [email] = useQueryParam("email", StringParam)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  console.log("email", email)

  // if (!email) {
  //   window.location.href = "/"
  // }

  const unsubscribe = () => {
    setIsLoading(true)
    fetch(
      (process.env.GATSBY_API_URL || "http://localhost:3000/production") +
        `/unsubscribe/` +
        email,
      {
        method: "POST",
      }
    ).then(() => {
      setIsLoading(false)
      setIsDone(true)
    })
  }

  return (
    <>
      <Header />
      <Fragment>
        <div className="text-center page-headline">
          <h1>Email Notification Opt-Out</h1>
          <p>Would you like to opt out this email list?</p>

          {isDone ? (
            <div>You have already opted out</div>
          ) : (
            <button
              disabled={isLoading}
              class="common-btn"
              onClick={unsubscribe}
            >
              <span>
                {isLoading ? "Loading" : "Yes, I would like to opt out."}
              </span>
            </button>
          )}
        </div>
      </Fragment>
      <Footer></Footer>
    </>
  )
}

export default UnsubscribePage
